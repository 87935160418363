import React, { useEffect, useRef } from "react";
import s from "./Line2.scss";

import gsap from "gsap";
import DrawSVGPlugin from "utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll";

export const Line2 = () => {
  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: "0%"
      },
      {
        duration: 4.5,
        delay: 2.5,
        drawSVG: "100%",
        ease: "linear",
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          start: "top 88%",
          end: "+=100px"
        }
      }
    );
  }, []);

  return (
    <svg
      className={s.line}
      xmlns="http://www.w3.org/2000/svg"
      width="1440"
      height="1838"
      viewBox="0 0 1440 1838"
      fill="none"
    >
      <path
        ref={lineRef}
        opacity="0.2"
        d="M1087.5 136V567M1427.52 1H1222C1147.44 1 1087 61.4416 1087 136M1427.5 1H1858.5M952.5 700.517C1027.06 700.517 1087.5 640.075 1087.5 565.517M549.02 1837H343.503C268.945 1837 208.503 1776.56 208.503 1702M208.5 1248V1702M549 1837H1858.5M952 700.5H-54M-189 979V835.483C-189 760.925 -128.558 700.483 -54 700.483M32 1113.81H73.5168C148.075 1113.81 208.517 1174.25 208.517 1248.81M32 1113.82L-53.2794 1113.81C-127.954 1113.81 -188.601 1053.49 -189 978.816"
        stroke="#1B52AD"
        stroke-width="2"
      />
    </svg>
  );
};
